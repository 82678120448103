import React from 'react';

import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import ArticleIndex from 'components/pages/article';
import SEO from 'components/SEO';
import { graphql, HeadFC } from 'gatsby';
import { CaseStudyArticleQueryResult } from './types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import HFSLayout from 'components/HFS/HFSLayout';
import LayoutContainer from 'components/LayoutContainer';
import { ArticlePageId } from 'components/types';

interface CaseStudyArticleTemplateProps {
  pageContext: {
    slug: string;
    title: string;
    description: string;
    cover: string;
    seo: null | {
      metaTitle: string;
      metaDescription: string;
      preventIndexing: boolean;
      canonicalURL: string;
    };
  };
  data: CaseStudyArticleQueryResult;
}
const CaseStudyArticleTemplate = ({ data }: CaseStudyArticleTemplateProps) => {
  return (
    <LanguageProvider messages={translation}>
      <HFSLayout headerStyle="light">
        <LayoutContainer>
          <ArticleIndex
            article={{
              ...data.allStrapiHfsCaseStudyArticle.nodes[0],
              category: {
                name: data.allStrapiHfsCaseStudyArticle.nodes[0]
                  .hfs_case_study_topic.name,
              },
            }}
            pageId={ArticlePageId.CASE_STUDIES}
          />
        </LayoutContainer>
      </HFSLayout>
    </LanguageProvider>
  );
};
export default CaseStudyArticleTemplate;

export const Head = ({
  pageContext: { title, description, cover, seo, slug },
}: HeadFC & CaseStudyArticleTemplateProps) => (
  <SEO
    pageTitle={seo?.metaTitle ?? title}
    description={seo?.metaDescription ?? description}
    ogImg={cover}
    isArticle={true}
    preventIndexing={seo?.preventIndexing}
    canonicalURL={`${PREFIX_CANONICAL_URL}/financial/case-studies/${slug}`}
  />
);

export const query = graphql`
  query ($slug: String) {
    allStrapiHfsCaseStudyArticle(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        publishedAt
        updatedAt
        description
        cover {
          alternativeText
          url
          formats {
            small {
              url
            }
          }
        }
        author {
          name
          strapi_id
        }
        hfs_case_study_topic {
          name
        }
        blocks {
          body {
            data {
              body
            }
          }
        }
      }
    }
  }
`;
